const theme = {
  fonts: {
    body:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    heading: 'NandosHandAlt',
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  lineHeights: {
    normal: 1.5,
    tight: 1.2,
  },
  colors: {
    primary: '#E31837',
    secondary: '#2C2C2C',
    text: '#2C2C2C',
    background: '#FFFFFF',
  },
  space: [0, 4, 8, 16, 24, 32, 48, 64, 96, 128],
  fontSizes: [12, 14, 16, 18, 20, 24, 30, 36, 48, 64, 72],
  buttons: {
    primary: {
      bg: 'primary',
      color: 'background',
      '&:hover': {
        bg: 'secondary',
      },
    },
    secondary: {
      bg: 'secondary',
      color: 'background',
      '&:hover': {
        bg: 'primary',
      },
    },
    tertiary: {
      bg: 'transparent',
      color: 'primary',
      border: '1px solid',
      borderColor: 'primary',
      '&:hover': {
        bg: 'primary',
        color: 'background',
      },
    },
  },
  buttonSizes: {
    small: {
      px: 2,
      py: 1,
      fontSize: 0,
    },
    medium: {
      px: 3,
      py: 2,
      fontSize: 1,
    },
    large: {
      px: 4,
      py: 3,
      fontSize: 2,
    },
  },
  headings: {
    h1: {
      fontSize: [7, 8, null, 9],
    },
    h2: {
      fontSize: [6, 7, null, 8],
    },
    h3: {
      fontSize: [5, 6, null, 7],
    },
    h4: {
      fontSize: [4, 5, null, 6],
    },
    h5: {
      fontSize: [3, 4, null, 5],
    },
  },
  text: {
    body: {
      fontSize: [1, 2],
      lineHeight: 'normal',
    },
  },
  transitionTimingFunction: {
    'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
};

export { theme };
