import { border, boxShadow, grid, position, system } from 'styled-system';
import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';

// @NOTE: color, space, typography, and flexbox are provided in entirety by Reflexbox.
const StyledFlex = styled(Flex)`
  ${border}
  ${grid}
  ${position}
  ${boxShadow}
  ${system({
    gap: { property: 'gap', scale: 'space' },
    rowGap: { property: 'rowGap', scale: 'space' },
    columnGap: { property: 'columnGap', scale: 'space' },
  })}
`;

export { StyledFlex as Flex };
