import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

const LinkComponentContext = createContext();
const LinkComponentProvider = LinkComponentContext.Provider;
const LinkComponentConsumer = LinkComponentContext.Consumer;
const useLinkComponent = () => useContext(LinkComponentContext);

const ImageComponentContext = createContext();
const ImageComponentProvider = ImageComponentContext.Provider;
const ImageComponentConsumer = ImageComponentContext.Consumer;
const useImageComponent = () => useContext(ImageComponentContext);

const ReactComponentsProvider = ({ children, theme, linkComponent, imageComponent }) => (
  <ThemeProvider theme={theme}>
    <LinkComponentProvider value={linkComponent}>
      <ImageComponentProvider value={imageComponent}>{children}</ImageComponentProvider>
    </LinkComponentProvider>
  </ThemeProvider>
);

ReactComponentsProvider.defaultProps = {
  theme: {},
  linkComponent: undefined,
  imageComponent: undefined,
};

ReactComponentsProvider.propTypes = {
  theme: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  linkComponent: PropTypes.func,
  imageComponent: PropTypes.func,
};

export { ReactComponentsProvider, useLinkComponent, LinkComponentConsumer, useImageComponent, ImageComponentConsumer };
