import { color, space, variant as styledVariant, typography } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';

const buttonVariant = styledVariant({
  scale: 'buttons',
  variants: {
    primary: {},
    secondary: {},
    tertiary: {},
  },
});

const sizeVariant = styledVariant({
  prop: 'size',
  scale: 'buttonSizes',
  variants: {
    large: {},
    medium: {},
    small: {},
  },
});

const buttonStyles = css`
  ${color};
  ${space};
  ${typography};
  ${buttonVariant};
  ${sizeVariant};
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.2s ${themeGet('transitionTimingFunction.in-out')};
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
`;

const shouldForwardProp = (prop, defaultValidatorFn) => !['isFullWidth'].includes(prop) && defaultValidatorFn(prop);

const StyledButton = styled.button.withConfig({
  shouldForwardProp,
})`
  ${buttonStyles}
`;

const Button = ({
  children,
  isDisabled,
  isFullWidth,
  isLoading,
  loadingIndicator,
  onClick,
  size,
  variant,
  type,
  ...props
}) => {
  return (
    <StyledButton
      {...props}
      disabled={isDisabled || isLoading}
      isFullWidth={isFullWidth}
      onClick={!isDisabled && !isLoading ? onClick : null}
      size={size}
      type={type}
      variant={variant}
    >
      {isLoading ? loadingIndicator : children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingIndicator: PropTypes.node,
  onClick: PropTypes.func,
  size: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  ...StyledSystemPropTypes.space,
  ...StyledSystemPropTypes.typography,
  ...StyledSystemPropTypes.color,
};

Button.defaultProps = {
  isDisabled: false,
  isFullWidth: false,
  isLoading: false,
  loadingIndicator: undefined,
  onClick: undefined,
  size: 'medium',
  variant: 'primary',
  type: undefined,
};

export { Button };
